<template>
  <div>
    <v-list subheader v-if="users.length > 0">
      <v-list-item
        v-for="user in users"
        :key="user.id"
        :class="
          user.account_enabled && !user.account_locked ? 'enabled' : 'disabled'
        "
        @click="gotoUser(user)"
      >
        <v-list-item-title class="py-2">
          <v-row wrap>
            <v-col cols="4" sm="2">
              <div>
                <v-chip x-small dark color="grey">{{ user.id }}</v-chip>
                <v-icon v-if="user.account_locked">mdi-lock</v-icon>
                <v-icon v-if="hasRole(user, 'ROLE_ASSOC_MANAGER')"
                  >mdi-account-tie</v-icon
                >
                <v-icon v-if="hasRole(user, 'ROLE_ADMIN')">mdi-crown</v-icon>
                <v-icon v-if="hasRole(user, 'ROLE_ISSUER')"
                  >mdi-text-box-plus</v-icon
                >
              </div>
            </v-col>
            <v-col cols="8" sm="2">
              <div class="text-truncate">{{ user.username }}</div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-truncate">{{ user.email }}</div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-truncate">
                {{ user.first_name }} {{ user.last_name }}
              </div>
            </v-col>
          </v-row>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    users: { type: Array, required: true }
  },
  methods: {
    gotoUser(user) {
      this.$router.push({ name: "user", params: { id: user.id } });
    },
    hasRole(user, roleName) {
      let adminRole = user.roles.find(role => role.name == roleName);
      return adminRole !== undefined;
    }
  }
};
</script>

<style scoped>
.v-list-item {
  border-left: 4px white solid;
}

.v-list-item.disabled {
  border-left-color: #e57373;
}
</style>
