<template>
  <div>
    <h3 class="text-h3">Users</h3>
    <v-form @submit.prevent="fetch">
      <v-text-field
        required
        label="Query by username or email"
        v-model="prefix"
      />
    </v-form>

    <div class="mb-4 caption">
      <div v-if="loading">Loading...</div>
      <div v-else>
        <div>
          {{ users.length }} results found (max page size is {{ pageSize }}).
        </div>
        <UserList :users="users" />
      </div>
    </div>
  </div>
</template>

<script>
import UserList from "@/components/UserList.vue";
import Backend from "@/services/Backend";

export default {
  components: {
    UserList
  },
  data() {
    return {
      users: [],
      loading: false,
      pageSize: 250,
      prefix: ""
    };
  },
  methods: {
    async fetch() {
      this.loading = true;
      this.users = [];
      let response = await Backend.getUsers(this.prefix, this.pageSize);
      this.users = response.data.sort((x, y) =>
        x.username < y.username ? -1 : 1
      );
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
